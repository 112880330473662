import React from "react";
import { View, Text, StyleSheet, Pressable } from "@/ui";
import { Colors, DESKTOP_WIDTH } from "@/constants";
import { useUISlice } from "@/models/useUISlice";
import LandingPageTitle from "./LandingPageTitle";
import { useUserSlice } from "@/models/useUserSlice";
import { useTranslation } from "react-i18next";
import { getLangDir } from "@/models/utils";

const LandingPageAction = ({ login }: { login: () => void }) => {
  const isMobile = useUISlice((state) => state.isMobile);
  const user = useUserSlice((state) => state.user);
  const { t } = useTranslation();
  const isRTL = getLangDir() === "rtl";

  if (user || isMobile) return null;

  return (
    <View
      style={{
        ...styles.container,
        paddingVertical: isMobile ? 50 : 100,
      }}
    >
      <LandingPageTitle title={t("landingPageAction.title")} />

      <Text style={styles.subtitle}>{t("landingPageAction.subtitle")}</Text>

      <View
        style={styles.ctaContainer && isMobile && { paddingHorizontal: 16 }}
      >
        <Pressable
          onPress={login}
          style={isMobile ? styles.mobilButton : styles.button}
        >
          <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZmlsbD0iIzQyODVGNCIgZD0iTTIyLjU2IDEyLjI1YzAtLjc4LS4wNy0xLjUzLS4yLTIuMjVIMTJ2NC4yNmg1LjkyYy0uMjYgMS4zNy0xLjA0IDIuNTMtMi4yMSAzLjMxdjIuNzdoMy41N2MyLjA4LTEuOTIgMy4yOC00Ljc0IDMuMjgtOC4wOXoiLz48cGF0aCBmaWxsPSIjMzRBODUzIiBkPSJNMTIgMjNjMi45NyAwIDUuNDYtLjk4IDcuMjgtMi42NmwtMy41Ny0yLjc3Yy0uOTguNjYtMi4yMyAxLjA2LTMuNzEgMS4wNi0yLjg2IDAtNS4yOS0xLjkzLTYuMTYtNC41M0gyLjE4djIuODRDMy45OSAyMC41MyA3LjcgMjMgMTIgMjN6Ii8+PHBhdGggZmlsbD0iI0ZCQkMwNSIgZD0iTTUuODQgMTQuMDljLS4yMi0uNjYtLjM1LTEuMzYtLjM1LTIuMDlzLjEzLTEuNDMuMzUtMi4wOVY3LjA3SDIuMThDMS40MyA4LjU1IDEgMTAuMjIgMSAxMnMuNDMgMy40NSAxLjE4IDQuOTNsMi44NS0yLjIyLjgxLS42MnoiLz48cGF0aCBmaWxsPSIjRUE0MzM1IiBkPSJNMTIgNS4zOGMxLjYyIDAgMy4wNi41NiA0LjIxIDEuNjRsMy4xNS0zLjE1QzE3LjQ1IDIuMDkgMTQuOTcgMSAxMiAxIDcuNyAxIDMuOTkgMy40NyAyLjE4IDcuMDdsMy42NiAyLjg0Yy44Ny0yLjYgMy4zLTQuNTMgNi4xNi00LjUzeiIvPjxwYXRoIGZpbGw9Im5vbmUiIGQ9Ik0xIDFoMjJ2MjJIMXoiLz48L3N2Zz4=" />
          <Text
            style={{
              ...styles.buttonText,
              ...(isRTL && { marginLeft: 0, marginRight: 8 }),
            }}
          >
            {t("landingPageAction.button")}
          </Text>
        </Pressable>
        <Text style={{ color: Colors.text, marginTop: 4 }}>
          {t("landingPageAction.freeMinutes")}{" "}
          <Text
            style={{
              fontWeight: "bold",
              lineHeight: 24,
              backgroundColor: "red",
              color: Colors.white,
              paddingVertical: 2,
              paddingHorizontal: 4,
            }}
          >
            {t("landingPageAction.freeMinutesAmount")}
          </Text>
          . {t("landingPageAction.noCreditCardNeeded")}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    maxWidth: DESKTOP_WIDTH,
    width: "100%",
    alignSelf: "center",
    paddingVertical: 100,
    alignItems: "center",
  },
  title: {
    fontSize: 48,
    fontWeight: "700",
    color: Colors.heading,
    lineHeight: 56,
    textAlign: "center",
    marginBottom: 16,
  },
  subtitle: {
    fontSize: 24,
    color: Colors.text,
    textAlign: "center",
    marginBottom: 48,
  },
  button: {
    flexDirection: "row",
    backgroundColor: Colors.white,
    borderWidth: 2.5,
    borderColor: Colors.text,
    paddingVertical: 20,
    paddingHorizontal: 40,
    borderRadius: 8,
    alignItems: "center",
    alignSelf: "flex-start",
  },
  mobilButton: {
    flexDirection: "row",
    backgroundColor: Colors.white,
    borderWidth: 2.5,
    borderColor: Colors.text,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 20,
    borderRadius: 8,
  },
  buttonText: {
    marginLeft: 12,
    color: Colors.text,
    fontSize: 18,
    fontWeight: "600",
  },
  ctaContainer: {
    alignItems: "center",
  },
});

export default LandingPageAction;
