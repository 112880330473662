import React from "react";
import { StyleSheet, Text, View } from "@/ui/index";
import {
  Colors,
  DESKTOP_WIDTH,
  PRICE_MONTHLY,
  PRICE_YEARLY,
} from "@/constants";
import PriceItem from "@/ui/PriceItem";
import DesktopContainer from "@/ui/DesktopContainer";
import MobileContainer from "@/ui/MobileContainer";
import { useUISlice } from "@/models/useUISlice";
import LandingPageTitle from "./LandingPageTitle";
import { useTranslation } from "react-i18next";
import LandingPageHeading from "./LandingPageHeading";

const LandingPricing = ({ login }: { login: () => void }) => {
  const isMobile = useUISlice((state) => state.isMobile);
  const { t } = useTranslation();

  const pricing = [
    {
      title: t("landingPricing.free"),
      subtitle: t("landingPricing.freeSubtitle"),
      price: "0",
      priceSubtitle: t("landingPricing.perMonth"),
      footerText1: t("landingPricing.first300minFREE"),
      footerText2: t("landingPricing.fullAccess"),
    },
    {
      title: t("landingPricing.monthly"),
      subtitle: t("landingPricing.monthlySubtitle"),
      price: PRICE_MONTHLY.toFixed(2),
      priceSubtitle: t("landingPricing.perMonth"),
      footerText1: t("landingPricing.unlimitedTranscription"),
      footerText2: t("landingPricing.fullAccess"),
    },
    {
      title: t("landingPricing.yearly"),
      subtitle: t("landingPricing.yearlySubtitle"),
      price: PRICE_YEARLY.toFixed(2),
      priceSubtitle: t("landingPricing.perYear"),
      footerText1: t("landingPricing.unlimitedTranscription"),
      footerText2: t("landingPricing.fullAccess"),
    },
  ];

  if (LandingPageHeading.forceInstall) return null;

  return (
    <>
      <MobileContainer>
        <View
          style={{
            paddingVertical: isMobile ? 50 : 100,
            backgroundColor: Colors.landingGray,
          }}
        >
          <LandingPageTitle title={t("landingPricing.mobileTitle")} />

          <View style={{ alignItems: "center" }}>
            {pricing.map((item, index) => (
              <View key={index}>
                <PriceItem
                  title={item.title}
                  isPrimary={index === 1}
                  priceSubtitle={item.priceSubtitle}
                  subtitle={item.subtitle}
                  footerText1={item.footerText1}
                  footerText2={item.footerText2}
                  price={item.price}
                  onPress={login}
                />
                {<View style={{ height: 32 }} />}
              </View>
            ))}
          </View>
        </View>
      </MobileContainer>

      <DesktopContainer>
        <View
          style={{ paddingVertical: 100, backgroundColor: Colors.landingGray }}
        >
          <View style={s.desktopWrapper}>
            <Text style={s.title}>{t("landingPricing.desktopTitle")}</Text>

            <View style={s.priceContainer}>
              {pricing.map((item, index) => (
                <PriceItem
                  key={index}
                  title={item.title}
                  priceSubtitle={item.priceSubtitle}
                  isPrimary={index === 1}
                  subtitle={item.subtitle}
                  footerText1={item.footerText1}
                  footerText2={item.footerText2}
                  price={item.price}
                  onPress={login}
                />
              ))}
            </View>
          </View>
        </View>
      </DesktopContainer>
    </>
  );
};

const s = StyleSheet.create({
  desktopWrapper: {
    maxWidth: DESKTOP_WIDTH,
    paddingHorizontal: 32,
    width: "100%",
    alignSelf: "center",
  },
  title: {
    color: Colors.title,
    alignSelf: "center",
    fontWeight: "700",
    fontSize: 48,
    lineHeight: 56,
    textAlign: "center",
  },
  mobileTitle: {
    color: Colors.title,
    alignSelf: "center",
    fontWeight: "800",
    fontSize: 32,
    lineHeight: 38,
    marginHorizontal: 16,
    textAlign: "center",
  },
  priceContainer: {
    marginTop: 100,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
});

export default LandingPricing;
